import React, { FC, useEffect, useState } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import Helmet from "react-helmet";
import { SEO } from "@/components/basic/SEO";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import { Link } from "@/components/basic/Link";
import { Carousel } from "@/components/basic/Carousel";
import { BlogPostBox } from "@/components/BlogPostBox";
import { JobBox } from "@/components/JobBox";
import ReactMarkdown from "react-markdown";
import { useMobileDetect } from "@/hooks/useMobileDetect";

const Jobs: FC<PageProps<GatsbyTypes.Query>> = ({ data }) => {
  const {
    pageMetaTitle,
    pageMetaDescription,
    pageMetaKeywords,
    bannerContent,
    careerDescription,
    careerBenefit,
    careerOfficeDescription,
    careerWorkHistory,
    officeLocationPhotos,
    openPositionsText,
    recruitmentSteps,
    bannerImage,
    bannerText,
  } = data.contentfulCareerPage;

  const newsList = (data.allContentfulNewsPost
    .edges as GatsbyTypes.ContentfulNewsPostEdge[]).sort(
    (a, b) => a.node.order - b.node.order
  );
  const jobsContent = data.allContentfulJobListing;
  const officeLocations = ["Wrocław", "Athens"];
  const jobLocations = ["All locations", ...officeLocations];
  const maxJobItems = 4;

  const [isLoaded, setIsLoaded] = useState(false);

  const [currentLocation, setLocation] = useState({
    selectedLocation: officeLocations[0],
    photos: [[]],
    key: Math.random(),
  });

  const [selectedJobs, setJobs] = useState({
    selectedJobLocation: jobLocations[0],
    jobs: [],
    showMore: false,
  });

  const [toggleRecruitmentSteps, setToggleRecruitmentSteps] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
    setLocationData(officeLocations[0]);
    setJobData(jobLocations[0]);
  }, []);

  function chunkByTwo<T>(inputArray: T[]): T[][] {
    return inputArray.reduce((resultArray, item, index) => {
      const perChunk = 2;
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

  function setJobData(jobLocation: string): void {
    let filteredJobs;
    if (jobLocation === jobLocations[0]) {
      filteredJobs = jobsContent.edges;
    } else {
      filteredJobs = jobsContent.edges.filter((value) =>
        value.node.location.toLowerCase().includes(jobLocation.toLowerCase())
      );
    }

    setJobs({
      ...selectedJobs,
      selectedJobLocation: jobLocation,
      jobs: filteredJobs,
    });
  }

  function toggleMoreJobs(): void {
    const showMore = !selectedJobs.showMore;
    setJobs({ ...selectedJobs, showMore });
  }

  function setLocationData(office: string): void {
    const locationPhotos = officeLocationPhotos
      .filter(({ locationAthens, locationWroclaw }) =>
        office === officeLocations[0] ? locationWroclaw : locationAthens
      )
      .sort((a, b) => a.order - b.order)
      .map((value) => value.officePhoto.file.url);

    setLocation({
      selectedLocation: office,
      photos: chunkByTwo(locationPhotos),
      key: Math.random(),
    });
  }

  // todo: carousel will be in future
  // const slides = [
  //   {
  //     heading: "Work with us",
  //     heroPhoto: "/images/career_photo.jpg",
  //   },
  // ];
  const { isMobile } = useMobileDetect(640);
  return (
    <MainLayout>
      <Helmet>
        <body className="ltr jobs" id="intro" />
      </Helmet>
      <SEO
        title={pageMetaTitle}
        description={pageMetaDescription.pageMetaDescription}
        keywords={pageMetaKeywords}
      />
      <div style={{ height: `${isMobile ? 280 : 346}px` }}>
        {isLoaded && (
          <section
            className="career-banner flex-box flex-align-center"
            style={{ backgroundImage: `url(${bannerImage?.file?.url})` }}
          >
            {bannerText && <h1 className="banner-title">{bannerText}</h1>}
          </section>
        )}
      </div>
      {/* todo: carousel will be in future */}
      {/*<div className="hero-carousel">*/}
      {/*  <Carousel interval={5000}>*/}
      {/*{slides.map(({ heroPhoto, heading }, index) => (*/}
      {/*  <HeroSlide*/}
      {/*    key={index}*/}
      {/*    index={index}*/}
      {/*    photoUrl={heroPhoto}*/}
      {/*    heading={heading}*/}
      {/*  />*/}
      {/*))}*/}
      {/*  </Carousel>*/}
      {/*</div>*/}
      <div className="hero-image">
        <div className="l-wrapper clearfix">
          {careerDescription &&
            (careerDescription as GatsbyTypes.ContentfulCareerDescription[])
              .sort((a, b) => a.priority - b.priority)
              .map(({ title, description }, index) => (
                <div
                  key={index}
                  className="l-4-cols-tablet hero-image-content-career"
                >
                  <h5 className="color-primary text-1-8">{title}</h5>
                  <p>{description.description}</p>
                </div>
              ))}
        </div>
      </div>
      <div className="testimonial-box color-bg-business-bg l-with-dbl-vertical-gutters-mobile my-career">
        <div className="l-wrapper">
          <div className="clearfix owl-carousel-grid">
            <div className="centred-elements l-with-gl-gutter-bottom-mobile">
              <h2 className="text-3 color-negative">My career at</h2>
              <div className="image-object">
                <img alt="ANIXE logo" src="/images/logo_white.png" />
              </div>
            </div>
            <div className="hero-carousel">
              <Carousel interval={5000} swipeable={true} emulateTouch={true}>
                {careerWorkHistory.map(
                  ({ bodyText, portraitPhoto, newsUrl }, index) => (
                    <a
                      key={index}
                      className="l-10-cols-desktop l-island clearfix owl-carousel-grid-40"
                      href={`/news/${newsUrl}`}
                    >
                      <div
                        className="centred-elements"
                        style={{ height: "200px" }}
                      >
                        <div className="quote-object l-push-bottom v-align-middle">
                          <div className="quote-object-item-career">
                            <div className="image-object">
                              <img src={portraitPhoto.file.url} />
                            </div>
                          </div>
                          <div className="quote-object-item">
                            <div className="image-object-career align-center">
                              <img
                                src="/images/quote_primary_white.svg"
                                alt="Quotation mark icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="l-dbl-push-bottom color-negative text-left">
                        {bodyText.bodyText}
                      </div>
                    </a>
                  )
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="l-wrapper color-bg-negative clearfix l-with-dbl-vertical-gutters-mobile">
        {newsList &&
          newsList
            .map((item) => item.node)
            .map(({ title, coverImage, lead, url, thumbnailImage }, index) => (
              <div
                key={index}
                className="d-block l-6-cols-tablet l-4-cols-desktop clearfix l-with-dbl-vertical-gutters-mobile"
              >
                <BlogPostBox
                  url={`/blog/${url}`}
                  date=""
                  title={title}
                  lead={lead}
                  coverImage={coverImage.file.url}
                  imageAlt={coverImage.title}
                  thumbnailImage={thumbnailImage.file.url}
                  isCareerPage={true}
                />
              </div>
            ))}
      </div>
      <div className="color-bg-business-bg l-with-gutter-bottom-mobile">
        <div className="l-wrapper clearfix l-dbl-push-bottom d-flex-box d-flex-box-wrap">
          <div className="l-12-cols-tablet l-with-vertical-gutters-mobile l-with-dbl-gutter-top-mobile l-with-gl-gutter-bottom-mobile">
            <h2 className="color-negative text-2">Perks</h2>
          </div>
          {careerBenefit &&
            (careerBenefit as GatsbyTypes.ContentfulCareerBenefit[])
              .sort((a, b) => a.order - b.order)
              .map(({ title, icon, description }, index) => (
                <div
                  key={index}
                  className="l-12-cols-mobile l-4-cols-tablet l-3-cols-desktop l-dbl-push-bottom l-with-dbl-gutter-right-mobile"
                >
                  <div className="d-flex-grow-1">
                    <div className="inline-table-tablet l-full-mobile l-bleed">
                      <div className="benefit image-object">
                        <img src={icon?.file?.url} />
                      </div>
                      <h4 className="color-negative l-with-gutter-top-mobile text-1-6">
                        {title}
                      </h4>
                      <div className="span horizontal-line"></div>
                      <p className="color-negative text-small l-with-half-gutter-top-mobile">
                        {description?.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </div>

      <div className="l-wrapper clearfix">
        <div className="l-12-cols-tablet l-with-vertical-gutters-mobile l-with-dbl-gutter-top-mobile">
          <h2 className="text-2 color-primary">Our place</h2>
          <div className="l-with-gl-gutter-bottom-mobile">
            <p>{careerOfficeDescription.careerOfficeDescription}</p>
          </div>
        </div>
      </div>
      <div className="color-bg-business-bg l-with-dbl-vertical-gutters-mobile">
        <div className="l-wrapper clearfix">
          <div className="l-12-cols-tablet">
            <div className="l-push-bottom l-push-top">
              <div className="inline-table-tablet l-bleed">
                <div className="inline-table-item-tablet l-with-gutter-after-mobile">
                  <span className="text-2 color-negative l-push-after">
                    Choose location:
                  </span>
                </div>
                <div className="inline-table-item-tablet">
                  {officeLocations.map((office, index) => (
                    <span
                      key={index}
                      className={`text-1-25 text-2-desktop color-negative l-push-after js-office-locations clickable-element ${
                        currentLocation.selectedLocation === office
                          ? "sorter-active"
                          : ""
                      }`}
                      data-city={office}
                      onClick={() => setLocationData(office)}
                    >
                      {office.toUpperCase()}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="office-location">
            <div className="l-12-cols-tablet color-negative">
              <Carousel
                key={currentLocation.key}
                interval={5000}
                swipeable={true}
                emulateTouch={true}
              >
                {currentLocation.photos.map((item, index) => (
                  <div key={index} className="slide-wrapper">
                    {item?.map((img, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={img}
                        alt={currentLocation.selectedLocation}
                        className="location-img"
                      />
                    ))}
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      {/*todo start*/}
      <div className="step-by-step-recruitment-padding" id="step-by-step-top">
        <div className="l-wrapper clearfix l-dbl-push-bottom d-flex-box d-flex-box-wrap">
          <div className="l-12-cols-tablet l-with-vertical-gutters-mobile l-with-dbl-gutter-top-mobile l-with-gl-gutter-bottom-mobile">
            <h3 className="text-2 color-primary">Step by step recruitment</h3>
          </div>
          <div className="wrapper">
            {recruitmentSteps.map(({ icon }, index) => (
              <React.Fragment key={index}>
                <div className="dot color-bg-primary centred-icon flex-box-center-x">
                  <div className="image-object career-icon">
                    <img src={icon.file.url} />
                  </div>
                </div>
                {recruitmentSteps.length - 1 > index && (
                  <div className="image-object arrow-career l-with-vertical-gutters-mobile">
                    <img src="/images/patterns/arrow_with_dot_primary_alt.svg" />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
        {toggleRecruitmentSteps && (
          <div className="recruitment-show-more color-bg-business-bg l-dbl-push-bottom l-wrapper clearfix">
            <div className="l-dbl-push-bottom d-flex-box d-flex-box-wrap l-with-gutters-mobile">
              {recruitmentSteps &&
                recruitmentSteps.map((item, index) => (
                  <div
                    key={index}
                    className="l-12-cols-mobile d-flex-grow-1 l-with-dbl-vertical-gutters-mobile"
                  >
                    <div className="l-full-mobile l-bleed">
                      <div className="inline-table-item-tablet v-align-top l-with-dbl-gutter-right-mobile">
                        <div className="dot-static color-bg-primary centred-icon">
                          <div className="image-object step-icon">
                            <img src={item.icon.file.url} alt={item.title} />
                          </div>
                        </div>
                      </div>
                      <div className="inline-table-item v-align-top l-with-dbl-gutter-before-tablet color-negative">
                        <h3 className="color-negative text-2">{item.title}</h3>
                        <ReactMarkdown allowDangerousHtml={true}>
                          {item.description.description}
                        </ReactMarkdown>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className="align-center">
          <button
            className="button button-primary-negative standardized-button-width"
            onClick={() => setToggleRecruitmentSteps(!toggleRecruitmentSteps)}
          >
            {toggleRecruitmentSteps ? "Hide" : "Some"} tips
          </button>
        </div>
      </div>
      {/*todo end*/}

      <section className="color-bg-business-bg l-with-dbl-vertical-gutters-mobile color-negative">
        <div className="l-wrapper clearfix">
          <div className="l-12-cols-tablet">
            <ReactMarkdown>{openPositionsText.openPositionsText}</ReactMarkdown>
          </div>
        </div>
      </section>

      <div
        className="l-wrapper color-bg-negative l-with-vertical-gutters-mobile l-with-dbl-gutter-top-mobile clearfix"
        id="job-offer-top"
      >
        <div className="l-10-cols-tablet l-7-cols-desktop l-island">
          <div className="l-push-bottom l-push-top">
            <div className="inline-table-tablet l-bleed">
              <div className="inline-table-item-tablet l-with-gutter-after-mobile">
                <span>Choose location:</span>
              </div>
              <div className="inline-table-item-tablet">
                {jobLocations.map((location, index) => (
                  <span
                    key={index}
                    className={`js - sort - locations clickable-element l-push-after ${
                      selectedJobs.selectedJobLocation === location
                        ? "sorter-active"
                        : ""
                    }`}
                    onClick={() => setJobData(location)}
                  >
                    {location.toUpperCase()}
                  </span>
                ))}
              </div>
            </div>
          </div>

          {selectedJobs.jobs
            .slice(0, selectedJobs.showMore ? undefined : maxJobItems)
            .map(({ node }, index) => (
              <JobBox
                key={index}
                url={node.bambooLink}
                title={node.jobTitle}
                lead={node.jobLead}
                location={node.location}
                icon={node.icon?.file.url}
                iconAlt={node.icon?.title}
              />
            ))}

          {selectedJobs.jobs.length >= maxJobItems && (
            <div className="align-center">
              <button
                className="button button-ghost-primary standardized-button-width"
                onClick={() => toggleMoreJobs()}
              >
                View {selectedJobs.showMore ? "less" : "more"}
              </button>
            </div>
          )}
        </div>
      </div>
      <section className="color-bg-business-bg l-with-dbl-vertical-gutters-mobile">
        <h3 className="color-negative text-bold align-center">
          {bannerContent}
        </h3>
        <div className="align-center l-dbl-push-top ">
          <Link
            classname="button button-primary-negative standardized-button-width"
            url="/jobs-contact-form"
            text="CONTACT WITH US"
          />
        </div>
      </section>
      <SiteFooterWrapper>
        <SiteFooterNewsletterButton />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default Jobs;
export const query = graphql`
  {
    contentfulCareerPage {
      pageMetaTitle
      bannerText
      bannerImage {
        file {
          url
        }
        description
      }
      pageMetaDescription {
        pageMetaDescription
      }
      openPositionsText {
        openPositionsText
      }
      pageMetaKeywords
      bannerContent
      careerBenefit {
        title
        description {
          description
        }
        icon {
          file {
            url
          }
        }
        order
      }
      careerDescription {
        title
        description {
          description
        }
        priority
      }
      careerOfficeDescription {
        careerOfficeDescription
      }
      careerWorkHistory {
        portraitPhoto {
          file {
            url
          }
        }
        newsUrl
        bodyText {
          bodyText
        }
      }
      officeLocationPhotos {
        locationAthens
        locationWroclaw
        order
        officePhoto {
          file {
            url
          }
        }
      }
      recruitmentSteps {
        order
        icon {
          file {
            url
          }
        }
        title
        description {
          description
        }
      }
    }
    allContentfulJobListing(sort: { fields: [updatedAt], order: DESC }) {
      edges {
        node {
          bambooLink
          jobLead
          jobTitle
          location
          icon {
            title
            file {
              url
            }
          }
        }
      }
    }
    allContentfulNewsPost(filter: { showOnCareerPage: { eq: true } }) {
      edges {
        node {
          title
          showOnCareerPage
          coverImage {
            file {
              url
            }
          }
          thumbnailImage {
            file {
              url
            }
          }
          lead
          order
          url
        }
      }
    }
  }
`;
